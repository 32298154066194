

export const About = () => {
  return (
    <div className="vh-100">
        <h1>About</h1>
        
        
    </div>
  )
}


