import './footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'; 
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
  
    <div className='footer mt-5 d-flex flex-column justify-content-between py-1'>
     
      <div className='d-flex justify-content-center align-items-center mt-4'>
        <FontAwesomeIcon icon={faFacebookF} className='facebook  rounded-circle mx-1' /> 
        <FontAwesomeIcon icon={faInstagram} className='instagram rounded-circle mx-1' />
        <FontAwesomeIcon icon={faLinkedinIn} className='linkedin rounded-circle mx-1'/>
      </div>
      <p>© 2024 Toate drepturile rezervate <Link to='/'>contalearn.ro</Link></p>
    </div>
  );
};
