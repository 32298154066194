import calculator from '../imagesHome/calculator.png';
import accounting from '../imagesHome/accounting.gif';
import exam from '../imagesHome/exam.gif';
import financialmanagement from '../imagesHome/financial-management.gif';
import financeanimation from '../imagesHome/finance-animation.gif';






import './Presentation.css';
import { Link } from 'react-router-dom';


export const Presentation = () => {
  return (
    <div className='container-presentation'>
        <div className='first-presentation'>
            <img src={calculator} alt='calculator' />
            <div className='text-button-presentation'>
                <h1 className='color-presentation'>Invata contabilitate de la 0</h1>
                <Link to='./registrer'>
                    <button className='buttons-presentation'>Incepe astazi!</button>
                </Link>
                <Link to='login'>
                    <button className='buttons-presentation-login'>Am deja cont!</button>
                </Link>
            </div>
        </div>

        <div className='betweeb-line-presentation'></div>

        <div className="d-flex justify-content-center align-items-center w-100">
            <Link to="/lessonscontainer" className="btn btn-primary px-5 fw-bold">
                Incearca testul demo!
            </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center w-100">
            <Link to="showlessons" className="btn btn-success px-5 fw-bold my-4">
                Vezi toate lectiile!  
            </Link>
        </div>

        <div className='betweeb-line-presentation'></div>

        <div className='colored-zone-presentation py-5'>
            <div className='first-presentation'>
                
                <div className='text-button-presentation'>
                    <h1 className='color-presentation-1 my-3 text-black'>Se apropie sesiunea si ai nevoie sa inveti rapid contabilitate?</h1>
                    <p className='text-presentation-1 my-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem impedit non soluta deserunt quidem quos, excepturi id iste aspernatur quaerat omnis dicta amet nostrum consequatur qui neque ipsum explicabo similique!</p>
                </div>
                <img src={exam} alt='exam' />
            </div>
        </div>

        <div className='betweeb-line-presentation'></div>

        <div className='first-presentation-second'>
            <img src={accounting} alt='accounting' />
            <div className='text-button-presentation'>
                <h1 className='color-presentation-1'>Pe ce se bazeaza cursul?</h1>
                <p className='text-presentation-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem impedit non soluta deserunt quidem quos, excepturi id iste aspernatur quaerat omnis dicta amet nostrum consequatur qui neque ipsum explicabo similique!</p>
            </div>
        </div>

        <div className='betweeb-line-presentation-1'></div>
        
        <div className='background-part-presentation'>
        <h1 className='inter-text-presentation'>Invata contabilitate de oriunde</h1>
        <img src={financeanimation} alt='financeanimation' />
        <Link>
            <button className='buttons-presentation fs-5 py-3'>Incearca 7 zile gratuit!</button>
        </Link>
        </div>
       


        <div className='third-part'>
            <h1 className='color-presentation-1'>Invata contabilitate cu ContaLearn</h1>
            
            <div className='text-button-presentation'>
                <Link>
                    <button className='buttons-presentation-login'>Incepe</button>
                </Link>
            </div>

          
            <div className='align-footer-presentation'>
                <img src={financialmanagement} alt='financialmanagement' />
            </div>
       

        </div>

       
        


      
    </div>
  )
}


