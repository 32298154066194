import '../LessonsContainer.css';
import questionsData from '../questionData';
import React, { useState, useEffect } from 'react';


import levelUp from '../../../header/images/level-up.mp3';
import error from '../../../header/images/error.mp3';
import finish from '../../../header/images/finish.mp3';
import confetti from '../../../header/images/confetty.gif';

export const Lesson = ({ changeStatusProgress, setScore, currentChapter }) => {
    //useState pentru intrebarea curenta
    const [currentQuestion, setCurrentQuestion] = useState(0);

    //useState pentru a prelua valoarea raspunsului curent
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    //useState pentru a aparea butonul verifica
    const [verifyButton, setVerifyButton] = useState(true);

    //useState pentru afisarea mesajului curent in functie de raspunsurile la intrebari
    const [currentMessage, setCurrentMessage] = useState("");

    //useState pentru mesajul de verifica raspunsul selectat
    const [buttonText, setButtonText] = useState("Verifica");

    //useState pentru confetti la final de lectie
    const [showConfetti, setShowConfetti] = useState(false);

    //useState pentru dezactivarea butoanelor dupa ce este verificat raspunsul
    const [isDesabledAndwers, setIsDesabledAndwers] = useState(false);

    //useState pentru modificarea culorii butonului continua
    const [continueColor, setContinueColor] = useState(true);

    // Număr de întrebări corecte
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0); 

    // Lista pentru întrebările greșite
    const [wrongQuestions, setWrongQuestions] = useState([]); 

    // Flag pentru a verifica dacă utilizatorul reia întrebările greșite
    const [retryingQuestions, setRetryingQuestions] = useState(false); 

    // Indexul curent pentru întrebările greșite
    const [retryIndex, setRetryIndex] = useState(0); 

    useEffect(() => {
        if (currentMessage === "Raspuns gresit") {
            setContinueColor(false); // Dacă răspunsul este greșit, schimbă culoarea în "greșit"
        } else if (currentMessage === "Raspuns corect") {
            setContinueColor(true); // Dacă răspunsul este corect, revine la culoarea standard
        } else if (currentMessage === "") {
            setContinueColor(true); // Dacă răspunsul este corect, revine la culoarea standard
        } 
    }, [currentMessage]);

    //functia pentru a prelua valoarea raspunsului selectat
    const handleSelectAnswer = (answer) => {
        setSelectedAnswer(answer);
        setVerifyButton(false);
    };

    //functia care verifica daca raspunsul selectat din parametrul answer este egal cu raspunsul corect
    const verifyAnswer = () => {
        if (selectedAnswer) {
            const isCorrect = selectedAnswer === questionsData[`capitolul${currentChapter}`][currentQuestion].correctAnswer;

            if (isCorrect) {
                setScore(prevScore => prevScore + 1);

                 // Creștem numărul de întrebări corecte și actualizăm bara de progres
                 setCorrectAnswersCount(prevCount => prevCount + 1);

                // Actualizăm bara de progres doar pentru răspunsurile corecte
                changeStatusProgress(correctAnswersCount + 1, questionsData[`capitolul${currentChapter}`].length); 
                
                setCurrentMessage("Raspuns corect");
                const audioUp = new Audio(levelUp);
                audioUp.play();
            } else {
                setCurrentMessage("Raspuns gresit");
                const audioErr = new Audio(error);
                audioErr.play();
                
                if (!wrongQuestions.includes(currentQuestion)) {
                    setWrongQuestions(prev => [...prev, currentQuestion]); // Adaugă întrebarea greșită doar dacă nu există deja
                }
            }

            if (isCorrect && currentQuestion === questionsData[`capitolul${currentChapter}`].length - 1 && wrongQuestions.length === 0) {
                setButtonText("Finalizează lecția");
            } else if (currentQuestion === questionsData[`capitolul${currentChapter}`].length - 1 && wrongQuestions.length > 0) {
                setButtonText("Reia întrebările greșite");
            } else {
                setButtonText("Continuă");
            }

            setIsDesabledAndwers(true);
        }
    };


    //functia care face ca lectiile sa continue atunci cand raspunsul este corect
    const handleContinue = () => {
        const totalQuestions = questionsData[`capitolul${currentChapter}`].length;

        if (!retryingQuestions && currentQuestion < totalQuestions - 1) {
            setCurrentQuestion(prevQuestion => prevQuestion + 1);
            setSelectedAnswer(null);
            setCurrentMessage("");
            setButtonText("Verifica");
        } else if (currentQuestion === totalQuestions - 1 && wrongQuestions.length > 0 && !retryingQuestions) {
            setRetryingQuestions(true);
            setRetryIndex(0);
            setCurrentQuestion(wrongQuestions[0]);
            setWrongQuestions(wrongQuestions.slice(1));
            setSelectedAnswer(null);
            setCurrentMessage("");
            setButtonText("Verifica din nou");
        } else if (retryingQuestions && retryIndex < wrongQuestions.length) {
            setCurrentQuestion(wrongQuestions[retryIndex]);
            setRetryIndex(prevIndex => prevIndex + 1);
            setSelectedAnswer(null);
            setCurrentMessage("");
            setButtonText("Verifica din nou");
        } else if (retryingQuestions && retryIndex === wrongQuestions.length) {
            const audioFinish = new Audio(finish);
            audioFinish.play();
            setShowConfetti(true);

           
              
           
        }

        setIsDesabledAndwers(false);
    };

  return (
    <div>
        <div className="questions">
            <h1 className='question-text text-center fs-3'>
                {questionsData[`capitolul${currentChapter}`][currentQuestion].question}
            </h1>
            <div className='line-question'></div>

            <div className='answers'>
            {questionsData[`capitolul${currentChapter}`][currentQuestion].options.map((item) => (
                    <button 
                    key={item}
                    onClick={ () =>  handleSelectAnswer(item)}
                    className={selectedAnswer === item ? 'answer-button-selected' : 'answer-button'}
                    disabled={isDesabledAndwers}
                    >
                        {item}
                    </button>
                ))}
            </div>
            {currentMessage && (
                <h2 className={currentMessage === "Raspuns corect" ? 'color-message-green' : 'color-message-red'}>
                    {currentMessage}
                </h2>
            )}
        
            <button 
            id='move-lesson-button'
                className={
                    selectedAnswer
                        ? continueColor
                            ? "continue-button"
                            : "continue-button-wrong"
                        : "continue-button-none"
                } 
                disabled={verifyButton} 
                onClick={currentMessage ? handleContinue : verifyAnswer}
            >
                {buttonText}
            </button>
        </div>

        {showConfetti && (
            <div className="confetti-container">
                <img src={confetti} alt="Confetti" className="confetti-image" />
            </div>
        )}
    </div>
  );
};
