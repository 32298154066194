import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Header } from './header/Header';
import { Home } from './content/Home';
import { About } from './content/About';
import { Contact } from './content/Contact';
import { Registrer } from './content/Registrer';
import { Login } from './content/Login';
import { Showlessons } from './content/Showlessons';
import { Footer } from './footer/Footer';
import './App.css';
import { LessonsContainer } from './content/lessons/LessonsContainer';
import Price from './header/Price';
import Blog from './content/Blog';
import InputLessons from './content/lessons/InputLessons';
import ScrollToTop from './ScrollToTop';

// Componenta care se ocupă de logica aplicației
function AppContent() {
  const location = useLocation(); 
  const lessonIsActive = location.pathname === '/lessonscontainer';

  return (
    <>
      {!lessonIsActive && <Header />}
      <div className="d-flex flex-column v-100">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='login' element={<Login />} />
          <Route path='registrer' element={<Registrer />} />
          <Route path='showlessons' element={<Showlessons />} />
          <Route path='/lessonscontainer' element={<LessonsContainer />} />
          <Route path='/price' element={<Price />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/inputlessons' element={<InputLessons />} />
        </Routes>
      </div>
      {!lessonIsActive && <Footer />}
    </>
  );
}

// Componenta principală care înfășoară aplicația în Router
function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;
