import {Link} from 'react-router-dom'
import card1 from '../imagesHome/business_1.gif';
import financeAnimation from '../imagesHome/premium.gif';
import cardOne from '../imagesHome/cardOne.gif';
import './price.scss';


const Price = () => {
  return (
    <div className='general-cards'>
        <div className="responsive-cards d-flex justify-content-center">
            <div className="item-card d-flex flex-column justify-content-center align-items-center m-2">
                <img src={cardOne} className="img-price my-3" alt="priceimage" />
                <div className="card-body d-flex flex-column my-4">
                    <h5 className='card-title text-success text-center my-2'>Personal</h5>
                    <h1 className='text-center fw-bold my-1'>Free</h1>
                    <ul>
                        <li>Avantaj 1</li>
                        <li>Avantaj 1</li>
                        <li>Avantaj 1</li>
                        <li>Avantaj 1</li>
                    </ul>
                    <Link to="#" className="btn btn-primary my-1">Start</Link>
                </div>
            </div>

            <div className="item-card d-flex flex-column justify-content-center align-items-center m-2">
                <img src={card1} className="img-price my-3" alt="priceimage" />
                <div className="card-body d-flex flex-column my-4">
                    <h5 className='card-title text-success text-center my-2'>Premium</h5>
                    <h1 className='text-center fw-bold my-1'>50 lei</h1>
                    <Link to="#" className="premium btn btn-warning fw-bold my-1">Start</Link>
                </div>
            </div>

            <div className="item-card d-flex flex-column justify-content-center align-items-center m-2">
                <img src={financeAnimation} className="img-price my-3" alt="priceimage" />
                <div className="card-body d-flex flex-column my-4">
                    <h5 className='card-title text-success text-center my-2'>Gold</h5>
                    <h1 className='text-center fw-bold my-1'>100 lei</h1>
                    <Link to="#" className="premium btn btn-warning fw-bold my-1">Start</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Price