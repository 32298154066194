import './showlessons.css';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwatchbook } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import icon1 from '../header/images/icon1.png';
import icon2 from '../header/images/icon2.png';
import icon3 from '../header/images/icon3.png';
import icon4 from '../header/images/icon4.png';
import icon5 from '../header/images/icon5.png';

export const Showlessons = () => {

  return (
    <div className='h-100 d-flex flex-column jusrify-content-center align-items-center'>

      <div class="colored-header-lesson py-4 rounded-4 d-flex justify-content-center align-items-center">
        <div className='text-white fs-2 mx-5'>Bazele contabilitatii</div>
        <FontAwesomeIcon icon={faSwatchbook} className='dimension-headers-lessons text-white mx-4 p-2 rounded-4' />
      </div>


    
      <div className='general-lessons'>
   
        <Link to='/lessonscontainer' className='new-lesson'>
          <button className='start-button'>Start</button>
          <img src={icon1} alt='icon' className='activate-lesson' />
        </Link>

        <Link to='/inputlessons' className='new-lesson'>
          
          <img src={icon2} alt='icon' className='activate-lesson' />
        </Link>

        <Link to='/lessonscontainer' className='new-lesson'>
         
          <img src={icon3} alt='icon' className='activate-lesson' />
        </Link>

        <Link to='/lessonscontainer' className='new-lesson'>
         
          <img src={icon4} alt='icon' className='activate-lesson' />
        </Link>

        <Link to='/lessonscontainer' className='new-lesson'>
          
          <img src={icon5} alt='icon' className='activate-lesson' />
        </Link>
      </div>


      <div class="colored-header-lesson py-4 rounded-4 d-flex justify-content-center align-items-center">
        <div className='text-white fs-2 mx-5'>Contabilitate financiara 1</div>
        <FontAwesomeIcon icon={faSwatchbook} className='dimension-headers-lessons text-white mx-4 p-2 rounded-4' />
      </div>


      <div className='general-lessons'>
   
   <Link to='/lessonscontainer' className='new-lesson'>
     <button className='start-button'>Start</button>
     <img src={icon1} alt='icon' className='activate-lesson' />
   </Link>

   <Link to='/lessonscontainer' className='new-lesson'>
     
     <img src={icon2} alt='icon' className='activate-lesson' />
   </Link>

   <Link to='/lessonscontainer' className='new-lesson'>
    
     <img src={icon3} alt='icon' className='activate-lesson' />
   </Link>

   <Link to='/lessonscontainer' className='new-lesson'>
    
     <img src={icon4} alt='icon' className='activate-lesson' />
   </Link>

   <Link to='/lessonscontainer' className='new-lesson'>
     
     <img src={icon5} alt='icon' className='activate-lesson' />
   </Link>
 </div>


      <div className='general-tittle-lessons-home'>
        <div className='line-arownd-tittle'></div>
        <h1 id='tittle-info'>Contabilitate financiara 1</h1>
        <div className='line-arownd-tittle'></div>
      </div>

    </div>
  );
};
