import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebAwesome } from '@fortawesome/free-brands-svg-icons'; 
import { faHouse } from '@fortawesome/free-solid-svg-icons'; 
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faBlogger } from '@fortawesome/free-brands-svg-icons'; 



import './menu.css';

export const Menu = () => {
  
    const [menuMobOpen, setMenuMobOpen] = useState(true);


    // Folosesc useLocation pentru a detecta schimbarea de pagină
  const location = useLocation();

  // Închide meniul mobil la fiecare schimbare de rută
  useEffect(() => {
    setMenuMobOpen(true); 
  }, [location]);


    // Functia care tine meniurile mobile ascunse si le inverseaza la click
    const handleMenuMobile = () => {
        setMenuMobOpen(!menuMobOpen);
    };

    // Functia care inchide meniurile mobile atunci cand este facut click pe acestea
    const handleMobileShow = () => {
        setMenuMobOpen(true);
    };

    return (
        <>
        <div className='menu-items'>
           <Link to='/' className='name-menu-item'>
           <FontAwesomeIcon icon={faHouse} className="mx-1" />
            Acasa
            </Link>
           <Link to='/showlessons' className='name-menu-item'>
           <FontAwesomeIcon icon={faBook} className='mx-1' />Cursuri
           </Link>
           <Link to='/blog' className='name-menu-item'>
           <FontAwesomeIcon icon={faBlogger} className="mx-1" />
            Blog
            </Link>
           <Link to='/price' 
           className='premium btn btn-warning fw-bold'>
            <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
            Premium
            </Link>
           
         
        </div>

        <div className='mobile-menu' onClick={handleMenuMobile}>
            <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
            <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
            <div className={menuMobOpen ? 'item-mobile-ball' : 'item-mobile-ball-open'}></div>
        </div>
        
        <div className={menuMobOpen ? 'mob-menu-open-none' : 'mob-menu-open'}>
           <Link to='/' className='name-menu-item-mob' onClick={handleMobileShow}>
           <FontAwesomeIcon icon={faHouse} className="mx-1" />
           Acasa
           </Link>
           <Link to='/showlessons' className='name-menu-item-mob' onClick={handleMobileShow}>
           <FontAwesomeIcon icon={faBook} className='mx-1' />
           Cursuri</Link>
           <Link to='/blog' className='name-menu-item-mob' onClick={handleMobileShow}>
           <FontAwesomeIcon icon={faBlogger} className="mx-1" />
           Blog</Link>
           <Link to='/price' className='name-menu-item-mob-premium' onClick={handleMobileShow}>
           <FontAwesomeIcon icon={faWebAwesome} className='mx-1' />
           Premium</Link>

           <div className='credentials-forms'>
             {/* Afișăm butoanele de logare și înregistrare DOAR dacă utilizatorul NU este logat */}
            
             
                 <Link to='login' className='form-menu-tabel design-ligin rounded-4 mx-1' onClick={handleMobileShow}>Conectare</Link>
                 <Link to='registrer' className='form-menu-tabel design-inregistrare rounded-4 mx-1' onClick={handleMobileShow}>Înregistrare</Link>
             
           
            
            
           </div>
        </div>
        </>
    );
};
