import './registrer.css';
import React, { useState } from 'react';




export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  

  const handleLogin = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    fetch('http://localhost:5000/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(errorData.message || 'A apărut o eroare');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Logare reușită:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error.message);
      });
  };

  return (
    <div className='vh-100'>
      <form className='registrer-form mt-4' onSubmit={handleLogin}>
        <h2 className='registrer-title fs-1'>Conecteaza-te!</h2>
        <label htmlFor='email' className='name-elemnts-form-reg'></label>
        <input
          type='email'
          id='email'
          name='email'
          className='input-reg'
          placeholder='Introdu emailul de logare'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor='password' className='name-elemnts-form-reg'></label>
        <input
          type='password'
          id='password'
          name='password'
          className='input-reg'
          placeholder='Introdu parola'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type='submit' className='input-reg-submit'>Logare</button>
      </form>

      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}



    </div>
  );
};
