

export const Contact = () => {
  return (
    <div className="vh-100">
        <h1>Contact</h1>
      
    </div>
  )
}


