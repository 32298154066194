import '../LessonsContainer.css';
import arrowExit from '../../../imagesHome/arrow-exit.png';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ProgressBar = ({ countProgress }) => {
  const navigate = useNavigate(); // Pentru a face redirecționarea

  const handleExitClick = () => {
    // Redirecționează la Showlessons când utilizatorul face click pe imagine
    navigate('/showlessons');
  };

  return (
    <div className='progress-bar-container'>
      {/* Redirecționează la Showlessons la click pe săgeată */}
      <img 
        src={arrowExit} 
        alt='arrowExit' 
        onClick={handleExitClick} 
        style={{ cursor: 'pointer' }} // Adăugăm un cursor pointer
      />

      <div className='progress-bar'>
        <div style={{
          backgroundColor: 'rgb(22, 199, 158)',
          borderRadius: `${countProgress < 100 ? '20px' : '20px'}`,
          width: `${countProgress}%`,
          height: '25px',
          transitionDuration: '1.5s',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
        }}>
          <div className='light-bar'></div>
        </div>
      </div>
    </div>
  );
}
