

const questionsData = {

    capitolul1: [
    {
        question: "Ce reprezinta conturile de activ?",
        options: ["bani", "bunuri", "creante", "toate de mai sus"],
        correctAnswer: "toate de mai sus",
    },
    {
        question: "Ce reprezinta conturile de pasiv?",
        options: ["datorii", "bunuri", "creante", "toate de mai sus"],
        correctAnswer: "datorii",
    },
    {
        question: "Ce este bilanțul contabil?",
        options: [
            "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
            "Un raport care prezintă doar veniturile și cheltuielile",
            "O listă cu toți angajații unei firme",
            "Un plan de afaceri"
        ],
        correctAnswer: "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
    },
    {
        question: "Ce reprezintă un activ în contabilitate?",
        options: [
            "O datorie a companiei",
            "O resursă care generează beneficii economice viitoare",
            "O pierdere contabilă",
            "O cheltuială"
        ],
        correctAnswer: "O resursă care generează beneficii economice viitoare",
    },
    {
        question: "Ce reprezintă un pasiv în contabilitate?",
        options: [
            "O obligație care duce la o ieșire de resurse",
            "Un venit suplimentar al firmei",
            "O creștere a activelor",
            "O reducere a capitalului propriu"
        ],
        correctAnswer: "O obligație care duce la o ieșire de resurse",
    },
    {
        question: "Ce este capitalul propriu?",
        options: [
            "Partea din active rămasă după deducerea tuturor datoriilor",
            "Un tip de datorie pe termen scurt",
            "Venituri din activitatea operațională",
            "Cheltuieli legate de salarii"
        ],
        correctAnswer: "Partea din active rămasă după deducerea tuturor datoriilor",
    },
    {
        question: "Ce este un cont contabil?",
        options: [
            "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
            "Un raport anual al companiei",
            "O listă cu acționarii firmei",
            "Un document care descrie politicile contabile ale firmei"
        ],
        correctAnswer: "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
    },
    {
        question: "Ce reprezintă amortizarea?",
        options: [
            "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
            "O metodă de creștere a capitalului propriu",
            "Un tip de pasiv pe termen lung",
            "Un mod de reducere a veniturilor"
        ],
        correctAnswer: "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
    },
    {
        question: "Care este diferența dintre contabilitatea de angajamente și contabilitatea de casă?",
        options: [
            "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
            "Contabilitatea de angajamente se referă doar la datorii, contabilitatea de casă doar la active",
            "Nu există nicio diferență între ele",
            "Contabilitatea de angajamente este folosită doar de marile corporații"
        ],
        correctAnswer: "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
    },
    {
        question: "Ce este un jurnal contabil?",
        options: [
            "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
            "Un raport trimestrial al profitului",
            "O metodă de calcul al amortizării",
            "Un cont de capital propriu"
        ],
        correctAnswer: "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
    },
    {
        question: "Ce este un cont de profit și pierdere?",
        options: [
            "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
            "Un document care descrie strategia firmei",
            "Un plan de afaceri",
            "Un tabel cu cheltuielile salariale"
        ],
        correctAnswer: "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
    },
],
    capitolul2: [
        {
            question: "Ce este un inventar în contabilitate?",
            options: [
                "O listă a tuturor activelor și pasivelor unei companii",
                "Un raport anual de venituri",
                "Un document care detaliază politica de amortizare",
                "Un tabel cu angajații firmei"
            ],
            correctAnswer: "O listă a tuturor activelor și pasivelor unei companii",
        },
        {
            question: "Ce reprezintă un activ curent?",
            options: [
                "Un activ care poate fi convertit în numerar într-un an",
                "Un activ imobilizat",
                "O datorie pe termen lung",
                "Un capital propriu"
            ],
            correctAnswer: "Un activ care poate fi convertit în numerar într-un an",
        },
        {
            question: "Ce este un audit financiar?",
            options: [
                "O evaluare independentă a situațiilor financiare ale unei companii",
                "Un raport intern de cheltuieli",
                "O metodă de calcul a profitului brut",
                "Un plan de reducere a costurilor"
            ],
            correctAnswer: "O evaluare independentă a situațiilor financiare ale unei companii",
        },
        {
            question: "Ce reprezintă un pasiv curent?",
            options: [
                "O datorie care trebuie plătită într-un an",
                "Un activ pe termen lung",
                "O investiție în acțiuni",
                "Un cont bancar"
            ],
            correctAnswer: "O datorie care trebuie plătită într-un an",
        },
        {
            question: "Ce este fluxul de numerar?",
            options: [
                "Mișcarea efectivă a banilor în și dintr-o companie",
                "Un document care prezintă bilanțul contabil",
                "Un plan de afaceri",
                "Un buget anual"
            ],
            correctAnswer: "Mișcarea efectivă a banilor în și dintr-o companie",
        },
        {
            question: "Ce este amortizarea liniară?",
            options: [
                "O metodă de alocare a costului unui activ în rate egale pe durata de viață utilă",
                "Un mod de calcul al capitalului propriu",
                "O metodă de evaluare a stocurilor",
                "Un proces de creștere a datoriilor"
            ],
            correctAnswer: "O metodă de alocare a costului unui activ în rate egale pe durata de viață utilă",
        },
        {
            question: "Ce reprezintă capitalul circulant?",
            options: [
                "Diferența dintre activele curente și pasivele curente",
                "Un fond destinat investițiilor pe termen lung",
                "O datorie a companiei",
                "Un cont de amortizare"
            ],
            correctAnswer: "Diferența dintre activele curente și pasivele curente",
        },
        {
            question: "Ce este analiza SWOT?",
            options: [
                "O evaluare a punctelor tari, punctelor slabe, oportunităților și amenințărilor unei companii",
                "Un calcul al profitului net",
                "O metodă de management al resurselor umane",
                "O tehnică de audit intern"
            ],
            correctAnswer: "O evaluare a punctelor tari, punctelor slabe, oportunităților și amenințărilor unei companii",
        },
        {
            question: "Ce reprezintă valoarea de piață a unei companii?",
            options: [
                "Prețul la care ar putea fi vândută compania pe piața liberă",
                "Valoarea contabilă a activelor firmei",
                "Totalul datoriilor firmei",
                "Profitul net anual"
            ],
            correctAnswer: "Prețul la care ar putea fi vândută compania pe piața liberă",
        },
        {
            question: "Ce este o analiză cost-beneficiu?",
            options: [
                "O evaluare a costurilor și beneficiilor asociate unei decizii de afaceri",
                "O metodă de calcul a prețului de vânzare",
                "Un raport anual al cheltuielilor",
                "O tehnică de negociere a contractelor"
            ],
            correctAnswer: "O evaluare a costurilor și beneficiilor asociate unei decizii de afaceri",
        },
    ],
    capitolul3: [
        {
            question: "Ce este o provizion în contabilitate?",
            options: [
                "O rezervă pentru obligații viitoare sau pierderi estimate",
                "O formă de capital propriu",
                "Un venit suplimentar",
                "O reducere a activelor"
            ],
            correctAnswer: "O rezervă pentru obligații viitoare sau pierderi estimate",
        },
        {
            question: "Ce reprezintă contabilitatea de gestiune?",
            options: [
                "Contabilitatea care se ocupă cu gestionarea costurilor și controlul intern",
                "Contabilitatea pentru raportările externe",
                "Contabilitatea care se ocupă cu înregistrarea activelor",
                "Contabilitatea folosită pentru impozite"
            ],
            correctAnswer: "Contabilitatea care se ocupă cu gestionarea costurilor și controlul intern",
        },
        {
            question: "Ce este o imobilizare corporală?",
            options: [
                "Un activ fizic pe termen lung utilizat în operațiunile unei firme",
                "Un activ financiar",
                "O datorie pe termen lung",
                "Un element de capital propriu"
            ],
            correctAnswer: "Un activ fizic pe termen lung utilizat în operațiunile unei firme",
        },
        {
            question: "Ce reprezintă valoarea contabilă netă a unui activ?",
            options: [
                "Valoarea activului după scăderea amortizării cumulate",
                "Valoarea de piață a activului",
                "Prețul de achiziție al activului",
                "Valoarea rămasă după deducerea datoriilor"
            ],
            correctAnswer: "Valoarea activului după scăderea amortizării cumulate",
        },
        {
            question: "Ce este o amortizare accelerată?",
            options: [
                "O metodă de amortizare care alocă costul unui activ într-un ritm mai rapid în primii ani de utilizare",
                "O metodă de evaluare a activelor la valoarea de piață",
                "O metodă de contabilizare a veniturilor",
                "Un proces de reducere a datoriilor"
            ],
            correctAnswer: "O metodă de amortizare care alocă costul unui activ într-un ritm mai rapid în primii ani de utilizare",
        },
        {
            question: "Ce este un activ intangibil?",
            options: [
                "Un activ non-fizic care are valoare economică, cum ar fi brevetele sau mărcile comerciale",
                "Un activ fizic, cum ar fi clădirile",
                "O datorie pe termen scurt",
                "Un instrument financiar"
            ],
            correctAnswer: "Un activ non-fizic care are valoare economică, cum ar fi brevetele sau mărcile comerciale",
        },
        {
            question: "Ce reprezintă un bilanț consolidat?",
            options: [
                "Un bilanț care combină activele, pasivele și capitalul propriu ale companiei mamă și ale filialelor sale",
                "Un bilanț care prezintă doar activele imobilizate",
                "Un bilanț intern utilizat pentru management",
                "Un bilanț care include doar activitatea operațională"
            ],
            correctAnswer: "Un bilanț care combină activele, pasivele și capitalul propriu ale companiei mamă și ale filialelor sale",
        },
        {
            question: "Ce este un raport de solvabilitate?",
            options: [
                "Un indicator care măsoară capacitatea unei firme de a-și plăti datoriile pe termen lung",
                "Un raport care arată profitabilitatea unei companii",
                "Un indicator al performanței pe termen scurt",
                "Un raport despre fluxurile de numerar"
            ],
            correctAnswer: "Un indicator care măsoară capacitatea unei firme de a-și plăti datoriile pe termen lung",
        },
        {
            question: "Ce reprezintă capitalizarea bursieră?",
            options: [
                "Valoarea totală de piață a acțiunilor unei companii tranzacționate public",
                "Totalul activelor unei companii",
                "Valoarea contabilă netă",
                "Totalul veniturilor anuale"
            ],
            correctAnswer: "Valoarea totală de piață a acțiunilor unei companii tranzacționate public",
        },
        {
            question: "Ce este analiza pe verticale (analiza structurii) în contabilitate?",
            options: [
                "Analiza procentuală a fiecărui element dintr-un raport financiar în raport cu un total ales (de exemplu, total active sau total venituri)",
                "Compararea performanței unei firme cu alte firme din aceeași industrie",
                "Analiza trendurilor pe o perioadă de timp",
                "Evaluarea performanței pe baza fluxurilor de numerar"
            ],
            correctAnswer: "Analiza procentuală a fiecărui element dintr-un raport financiar în raport cu un total ales (de exemplu, total active sau total venituri)",
        },
    ],
    capitolul4: [
        {
            question: "Ce reprezinta conturile de activ?",
            options: ["bani", "bunuri", "creante", "toate de mai sus"],
            correctAnswer: "toate de mai sus",
        },
        {
            question: "Ce reprezinta conturile de pasiv?",
            options: ["datorii", "bunuri", "creante", "toate de mai sus"],
            correctAnswer: "datorii",
        },
        {
            question: "Ce este bilanțul contabil?",
            options: [
                "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
                "Un raport care prezintă doar veniturile și cheltuielile",
                "O listă cu toți angajații unei firme",
                "Un plan de afaceri"
            ],
            correctAnswer: "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
        },
        {
            question: "Ce reprezintă un activ în contabilitate?",
            options: [
                "O datorie a companiei",
                "O resursă care generează beneficii economice viitoare",
                "O pierdere contabilă",
                "O cheltuială"
            ],
            correctAnswer: "O resursă care generează beneficii economice viitoare",
        },
        {
            question: "Ce reprezintă un pasiv în contabilitate?",
            options: [
                "O obligație care duce la o ieșire de resurse",
                "Un venit suplimentar al firmei",
                "O creștere a activelor",
                "O reducere a capitalului propriu"
            ],
            correctAnswer: "O obligație care duce la o ieșire de resurse",
        },
        {
            question: "Ce este capitalul propriu?",
            options: [
                "Partea din active rămasă după deducerea tuturor datoriilor",
                "Un tip de datorie pe termen scurt",
                "Venituri din activitatea operațională",
                "Cheltuieli legate de salarii"
            ],
            correctAnswer: "Partea din active rămasă după deducerea tuturor datoriilor",
        },
        {
            question: "Ce este un cont contabil?",
            options: [
                "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
                "Un raport anual al companiei",
                "O listă cu acționarii firmei",
                "Un document care descrie politicile contabile ale firmei"
            ],
            correctAnswer: "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
        },
        {
            question: "Ce reprezintă amortizarea?",
            options: [
                "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
                "O metodă de creștere a capitalului propriu",
                "Un tip de pasiv pe termen lung",
                "Un mod de reducere a veniturilor"
            ],
            correctAnswer: "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
        },
        {
            question: "Care este diferența dintre contabilitatea de angajamente și contabilitatea de casă?",
            options: [
                "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
                "Contabilitatea de angajamente se referă doar la datorii, contabilitatea de casă doar la active",
                "Nu există nicio diferență între ele",
                "Contabilitatea de angajamente este folosită doar de marile corporații"
            ],
            correctAnswer: "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
        },
        {
            question: "Ce este un jurnal contabil?",
            options: [
                "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
                "Un raport trimestrial al profitului",
                "O metodă de calcul al amortizării",
                "Un cont de capital propriu"
            ],
            correctAnswer: "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
        },
        {
            question: "Ce este un cont de profit și pierdere?",
            options: [
                "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
                "Un document care descrie strategia firmei",
                "Un plan de afaceri",
                "Un tabel cu cheltuielile salariale"
            ],
            correctAnswer: "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
        },
    ],
    capitolul5: [
        {
            question: "Ce reprezinta conturile de activ?",
            options: ["bani", "bunuri", "creante", "toate de mai sus"],
            correctAnswer: "toate de mai sus",
        },
        {
            question: "Ce reprezinta conturile de pasiv?",
            options: ["datorii", "bunuri", "creante", "toate de mai sus"],
            correctAnswer: "datorii",
        },
        {
            question: "Ce este bilanțul contabil?",
            options: [
                "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
                "Un raport care prezintă doar veniturile și cheltuielile",
                "O listă cu toți angajații unei firme",
                "Un plan de afaceri"
            ],
            correctAnswer: "Un document financiar care reflectă situația financiară a unei entități la un moment dat",
        },
        {
            question: "Ce reprezintă un activ în contabilitate?",
            options: [
                "O datorie a companiei",
                "O resursă care generează beneficii economice viitoare",
                "O pierdere contabilă",
                "O cheltuială"
            ],
            correctAnswer: "O resursă care generează beneficii economice viitoare",
        },
        {
            question: "Ce reprezintă un pasiv în contabilitate?",
            options: [
                "O obligație care duce la o ieșire de resurse",
                "Un venit suplimentar al firmei",
                "O creștere a activelor",
                "O reducere a capitalului propriu"
            ],
            correctAnswer: "O obligație care duce la o ieșire de resurse",
        },
        {
            question: "Ce este capitalul propriu?",
            options: [
                "Partea din active rămasă după deducerea tuturor datoriilor",
                "Un tip de datorie pe termen scurt",
                "Venituri din activitatea operațională",
                "Cheltuieli legate de salarii"
            ],
            correctAnswer: "Partea din active rămasă după deducerea tuturor datoriilor",
        },
        {
            question: "Ce este un cont contabil?",
            options: [
                "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
                "Un raport anual al companiei",
                "O listă cu acționarii firmei",
                "Un document care descrie politicile contabile ale firmei"
            ],
            correctAnswer: "Un element folosit pentru a înregistra și a urmări tranzacțiile financiare",
        },
        {
            question: "Ce reprezintă amortizarea?",
            options: [
                "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
                "O metodă de creștere a capitalului propriu",
                "Un tip de pasiv pe termen lung",
                "Un mod de reducere a veniturilor"
            ],
            correctAnswer: "Procesul de alocare a costului unui activ pe durata de viață utilă a acestuia",
        },
        {
            question: "Care este diferența dintre contabilitatea de angajamente și contabilitatea de casă?",
            options: [
                "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
                "Contabilitatea de angajamente se referă doar la datorii, contabilitatea de casă doar la active",
                "Nu există nicio diferență între ele",
                "Contabilitatea de angajamente este folosită doar de marile corporații"
            ],
            correctAnswer: "Contabilitatea de angajamente înregistrează tranzacțiile la angajare, contabilitatea de casă la plată sau încasare",
        },
        {
            question: "Ce este un jurnal contabil?",
            options: [
                "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
                "Un raport trimestrial al profitului",
                "O metodă de calcul al amortizării",
                "Un cont de capital propriu"
            ],
            correctAnswer: "Un document în care se înregistrează cronologic toate tranzacțiile financiare",
        },
        {
            question: "Ce este un cont de profit și pierdere?",
            options: [
                "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
                "Un document care descrie strategia firmei",
                "Un plan de afaceri",
                "Un tabel cu cheltuielile salariale"
            ],
            correctAnswer: "Un raport financiar care prezintă veniturile, cheltuielile și rezultatul net",
        },
    ],
};


export default questionsData;
