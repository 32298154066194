import './LessonsContainer.css';
import React, { useState, useEffect } from 'react';
import { ProgressBar } from './LessonComponents/ProgressBar';
import { Lesson } from './LessonComponents/Lesson';
import { useLocation,  } from 'react-router-dom';

export const LessonsContainer = () => { 
    const [countProgress, setCountProgress] = useState(0);
    const [score, setScore] = useState(0);

    // Obține capitolul din `state` folosind `useLocation`
    const location = useLocation();
    const { chapter } = location.state || { chapter: 1 }; // Capitol implicit 1

  

    // Actualizarea progresului doar pentru întrebări corecte
    const changeStatusProgress = (currentQuestion, totalQuestions) => {
      setCountProgress(((currentQuestion + 1) / totalQuestions) * 100);
    };
  
    useEffect(() => {
      if (countProgress === 100) {
        fetch('http://localhost:5000/api/updateScore', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ score }), 
        })
        .then(response => response.json())
        .then(data => {
          console.log('Scor actualizat cu succes:', data);
        })
        .catch(error => {
          console.error('Eroare la actualizarea scorului:', error);
        });

      }
    }, [countProgress, score]); // finalizeChapter trebuie inclus ca dependență
  
    return (
      <div className='lessons-general-container '>
        <ProgressBar countProgress={countProgress} />
        {/* Pasăm capitolul curent */}
        <Lesson 
          changeStatusProgress={changeStatusProgress} 
          setScore={setScore} 
          currentChapter={chapter} // Transmitem capitolul din state
        />
      
      </div>
    );
};
