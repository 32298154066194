import './registrer.css';
import React, { useState } from 'react';


export const Registrer = () => {
  const [nume, setNume] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatpass, setRepeatpass] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Activează starea de "loading"

    // Funcția de validare a emailului
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    // Funcția de validare a parolei
    const validatePassword = (password) => {
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
      if (password.length < minLength) {
        return 'Parola trebuie să aibă cel puțin 8 caractere';
      }
      if (!hasUpperCase) {
        return 'Parola trebuie să conțină cel puțin o literă mare';
      }
      if (!hasLowerCase) {
        return 'Parola trebuie să conțină cel puțin o literă mică';
      }
      if (!hasNumber) {
        return 'Parola trebuie să conțină cel puțin un număr';
      }
      if (!hasSpecialChar) {
        return 'Parola trebuie să conțină cel puțin un caracter special';
      }
      return ''; // Dacă toate condițiile sunt îndeplinite
    };

    // Validarea emailului
    if (!validateEmail(email)) {
      setErrorMessage('Adresa de email este invalidă');
      setIsLoading(false); // Dezactivează starea de "loading"
      return;
    }

    // Validarea parolei
    const passwordError = validatePassword(password);
    if (passwordError) {
      setErrorMessage(passwordError);
      setIsLoading(false); // Dezactivează starea de "loading"
      return;
    }

    // Validarea parolelor
    if (password !== repeatpass) {
      setErrorMessage('Parolele nu se potrivesc');
      setIsLoading(false); // Dezactivează starea de "loading"
      return;
    }

    // Variabila care preia datele pentru a le trimite către server
    const userData = {
      nume,
      email,
      password,
    };

    // Trimiterea datelor folosind fetch API
    fetch('http://localhost:5000/api/accounts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw new Error(errorData.message || 'A apărut o eroare');
        });
      }
      return response.json();
    })
    .then((data) => {
      setNume('');
      setEmail('');
      setPassword('');
      setRepeatpass('');
      setSuccessMessage('Contul a fost creat cu succes!');
      setErrorMessage('');
      console.log(data);
    })
    .catch((error) => {
      setErrorMessage(error.message);
      console.error('Error:', error);
    })
    .finally(() => {
      setIsLoading(false); // Dezactivează starea de "loading"
    });
  };



  return (
    <div className='vh-100'>
      <form className="registrer-form mt-4" onSubmit={handleSubmit}>
        <h2 className='registrer-title fs-1'>Creeaza-ti cont!</h2>
          <label htmlFor="nume" className="name-elemnts-form-reg"></label>
          <input
            type="text"
            id="nume"
            name="nume"
            className="input-reg w-75"
            placeholder="Nume si prenume"
            value={nume}
            onChange={(e) => setNume(e.target.value)}
            required
          />
         
      
        <label htmlFor="email" className="name-elemnts-form-reg"></label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          className="input-reg w-75"
          placeholder="Introdu o adresă de email"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password" className="name-elemnts-form-reg"></label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          className="input-reg w-75"
          placeholder="Creează o parolă"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label htmlFor="repeatpass" className="name-elemnts-form-reg"></label>
        <input
          type="password"
          id="repeatpass"
          name="repeatpass"
          value={repeatpass}
          className="input-reg w-75"
          placeholder="Reintrodu parola"
          onChange={(e) => setRepeatpass(e.target.value)}
          required
        />
        <button type="submit" className="input-reg-submit" disabled={isLoading}>
          {isLoading ? 'Se înregistrează...' : 'Creează cont'}
        </button>
      </form>

      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
