import './home.css';
import { Presentation } from './Presentation';


export const Home = () => {

  return (
    <div className='objects-home'>
    <Presentation /> 
   
    </div>
  );
};
