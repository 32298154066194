import { Menu } from './Menu';
import { Logo } from './Logo';
import './header.css';

export const Header = () => {
  return (
    <div className='align-header-elements'>
        <Logo />
        <Menu />
      
    </div>
  )
}
